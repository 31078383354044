module.exports = {
  "IDMClientOptions": {
    "externalAuth": false,
    "cookiesEndpoint": "https://auth.ibew.ikonint-dev.com",
    "apiEndpoint": "https://api-auth.ibew.ikonint-dev.com",
    "roles": {
      "USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "ADMIN": "MY-APP-ADMIN-ROLE",
      "ROOT": "59a72bbd-9a43-427e-9c92-afd74ab19433"
    },
    "partitions": {
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "IncludesStageOnName": true,
  "Stage": "dev",
  "ApplicationRecaptchaKey": "6Ld5uMwZAAAAALUYoCDAL5dW5dC7rp3QfUg41Rl0",
  "ApplicationAdvancedSearchDisabled": false,
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "register": "/register",
    "registrationConfirmation": "/registrationConfirmation/:id/:id2",
    "registrationConfirmationMail": "/registrationConfirmation/:id",
    "registrationConfirmationReset": "/registrationConfirmation/reset/:id",
    "resetPassword": "/resetPass/:id",
    "resetPasswordRaw": "/resetPass/",
    "resetPasswordConfirmation": "/resetPass/confirm/:id",
    "setupPassword": "/setupPass/:id/:id2",
    "user": "/user/:id",
    "profile": "/user",
    "users": "/users",
    "applications": "/apps",
    "application": "/app/:id",
    "role": "/app/:id/role/:id2",
    "accessToken": "/app/:id/accessToken/:id2",
    "partitionACLs": "/acls",
    "partitionACL": "/acl/:id",
    "authError": "/authError",
    "smsMFA": "/smfa",
    "contactAdmin": "mailto:support@ikonintegration.com"
  },
  "ThemeDomainsMapping": {
    "*.ibew.ikonint-dev.com": {
      "themeKey": "ibew",
      "adminRole": "NONE",
      "userRole": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "idmKey": "3d1d6d79-4700-5da4-a3b4-1734f8d9964d",
      "idmSecret": "e2da151f-55aa-57ed-8b7f-ade8af7611bd",
      "idmExternalAuthDomain": "auth.ibew.ikonint-dev.com"
    },
    "localhost:3000": {
      "themeKey": "ibew",
      "adminRole": "NONE",
      "userRole": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "idmKey": "3d1d6d79-4700-5da4-a3b4-1734f8d9964d",
      "idmSecret": "e2da151f-55aa-57ed-8b7f-ade8af7611bd",
      "idmExternalAuthDomain": "auth.ibew.ikonint-dev.com"
    }
  },
  "DatadogOptions": {
    "applicationId": "a8039f15-8b00-4cae-86af-e5f95cd24ece",
    "clientToken": "pub1e0e6e2f2186d5b5c5d0b66627a2481f",
    "site": "datadoghq.com",
    "service": "idm-fe",
    "env": "dev",
    "sampleRate": 100,
    "trackInteractions": true,
    "defaultPrivacyLevel": "allow"
  }
}